import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import SkiImg from '../../../../img/ski.jpeg';
import Item from '../../../../interfaces/itemInterface';

import styles from './SkiSection.module.scss';

function SkiSection() {
  const [skis, setSkis] = useState<Array<Item>>([]);

  useEffect(() => {
    const apiUrl = 'db/ski.json';
    axios.get(apiUrl).then((resp) => {
      const allSki = resp.data;
      setSkis(allSki);
    });
  }, []);

  return (
    <section className={styles.ski}>
      <div className="wrap">
        <h2 className={styles.title}>Лыжи</h2>
        <div className={styles.column}>
          <img className={styles.img} src={SkiImg} alt="" />
          <div className={styles.wrapItems}>
            {skis.map((ski) => (
              <div className={styles.item} key={ski.id}>
                <div className={styles.wrapImgItem}>
                  <img className={styles.imgItem} src={ski.img} alt="bike" />
                  {/* <img className={styles.cartIcon} src={Cart} alt="" /> */}
                </div>
                <p className={styles.titleItem}>{ski.name}</p>
                <p className={styles.priceItem}>
                  {new Intl.NumberFormat('ru').format(ski.price)}
                  {' '}
                  руб.
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.wrapButton}>
          <Link className={styles.button} to="/contact">Посмотреть все лыжи в магазине</Link>
        </div>
      </div>
    </section>
  );
}

export default SkiSection;
