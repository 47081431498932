import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import './OneNewsPage.scss';
import NewsItem from '../../interfaces/newsInterface';

function OneNewsPage() {
  const { id } = useParams();
  const [news, setNews] = useState({
    id: '',
    img: '',
    imgOneNews: '',
    text: '',
    title: '',
    winter: false,
  });

  useEffect(() => {
    const apiUrl = '/db/news.json';
    axios.get(apiUrl).then((resp) => {
      const allNews = resp.data;
      const oneNews = allNews.find((news: NewsItem) => news.id === id);
      setNews(oneNews);
    });
  }, [id]);

  return (
    <>
      <div
        className="news_newsPhoto"
        style={{
          backgroundImage: `url(${news.imgOneNews})`,
          backgroundColor: `${news.winter ? '#2437ca' : '#924711'}`,
        }}
      />
      <div className="wrap">
        <h2 className="news_subtitle">{news.title}</h2>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: news.text }} />
      </div>
      <div className="news_wrapButton">
        <Link
          className={`${news.winter ? 'news_button' : 'news_button summer_button'}`}
          to="/news"
        >
          Посмотреть все новости
        </Link>
      </div>
    </>
  );
}

export default OneNewsPage;
