import styles from './NewCollection.module.scss';

function NewCollection() {
  return (
    <section className={styles.newCollection}>
      <div className={styles.bgShadow} />
      <div className="wrap">
        <div className={styles.textWrap}>
          <h1 className={styles.title}>Новая коллекция</h1>
          <h2 className={styles.subtitle}>Зима 2022 - 2023 уже в магазине</h2>
        </div>
      </div>
    </section>
  );
}

export default NewCollection;
