import styles from './Switcher.module.scss';

function Switcher({ isWinter, setIsWinter, closeMobileMenu } :
                    { isWinter: boolean,
                      setIsWinter: () => void,
                      closeMobileMenu?: () => void
                    }) {
  return (
    <div className={styles.wrap}>
      <span className={styles.leftSpan}>Лето</span>
      <label className={styles.switch} htmlFor="toogle">
        <input
          type="checkbox"
          id="toogle"
          checked={isWinter}
          onChange={setIsWinter}
        />
        <span onClick={closeMobileMenu} className={`${styles.slider} ${styles.round}`} />
      </label>
      <span className={styles.rightSpan}>Зима</span>
    </div>
  );
}

export default Switcher;
