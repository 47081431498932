import NavMenu from '../NavMenu/NavMenu';
import styles from './Header.module.scss';
import NavMenuMobile from '../NavMenuMobile/NavMenuMobile';

function Header({ isWinter, setIsWinter } : { isWinter: boolean, setIsWinter: () => void }) {
  return (
    <header className={styles.header}>
      <div className="wrap">
        <NavMenu isWinter={isWinter} setIsWinter={setIsWinter} />
      </div>
      <NavMenuMobile isWinter={isWinter} setIsWinter={setIsWinter} />
    </header>
  );
}

export default Header;
