import styles from './Footer.module.css';
import NavMenu from '../NavMenu/NavMenu';

function Footer({ isWinter, setIsWinter } : { isWinter: boolean, setIsWinter: () => void }) {
  return (
    <footer className={`wrap ${styles.footer}`}>
      <NavMenu isWinter={isWinter} setIsWinter={() => setIsWinter} />
    </footer>
  );
}

export default Footer;
