import styles from './BrandsWinter.module.scss';
import ImgFischer from '../../../../img/fischer.svg';
import ImgHead from '../../../../img/head.svg';
import ImgSwix from '../../../../img/swix.svg';
import ImgPrime from '../../../../img/prime.svg';

function BrandsWinter() {
  return (
    <section className={styles.services}>
      <div className={styles.bgShadow} />
      <div className="wrap">
        <div className={styles.textWrap}>
          <img className={styles.img} src={ImgPrime} alt="" />
          <img className={styles.img} src={ImgFischer} alt="" />
          <img className={styles.imgSwix} src={ImgSwix} alt="" />
          <img className={styles.img} src={ImgHead} alt="" />
        </div>
      </div>
    </section>
  );
}

export default BrandsWinter;
