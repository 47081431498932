import styles from './BrandsSummer.module.scss';
import ImgScott from '../../../../img/scott.svg';
import ImgFormat from '../../../../img/format.svg';
import ImgTerror from '../../../../img/terror.png';
import ImgTramp from '../../../../img/tramp.png';

function BrandsSummer() {
  return (
    <section className={styles.services}>
      <div className={styles.bgShadow} />
      <div className="wrap">
        <div className={styles.textWrap}>
          <img className={styles.img} src={ImgTramp} alt="" />
          <img className={styles.img} src={ImgScott} alt="" />
          <img className={styles.imgPng} src={ImgTerror} alt="" />
          <img className={styles.img} src={ImgFormat} alt="" />
        </div>
      </div>
    </section>
  );
}

export default BrandsSummer;
