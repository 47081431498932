import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import NewsItem from '../../../../interfaces/newsInterface';

import styles from './News.module.scss';

function News() {
  const [newsSummer, setNewsSummer] = useState<Array<NewsItem>>([]);

  useEffect(() => {
    const apiUrl = 'db/news.json';
    axios.get(apiUrl).then((resp) => {
      const allNews = resp.data;
      const allNewsSummer = allNews.filter((oneNews: NewsItem) => oneNews.winter === false);
      const last3News = allNewsSummer.slice(0, 3);
      setNewsSummer(last3News);
    });
  }, []);

  return (
    <section className={styles.news}>
      <div className="wrap">
        <h2 className={styles.title}>Новости</h2>
        <div className={styles.column}>
          {newsSummer.map((newsItem) => (
            <div className={styles.newsItem} key={newsItem.id}>
              <Link to={`/news/${newsItem.id}`}>
                <img className={styles.imgNews} src={newsItem.img} alt="" />
              </Link>
              <Link className={styles.titleNews} to={`/news/${newsItem.id}`}>
                {newsItem.title}
              </Link>
              <Link className={styles.linkNews} to={`/news/${newsItem.id}`}>Читать...</Link>
            </div>
          ))}
        </div>
        <div className={styles.wrapButton}>
          <Link className={styles.button} to="/news">Посмотреть все новости</Link>
        </div>
      </div>
    </section>
  );
}

export default News;
