import './App.scss';
import { Route, Routes } from 'react-router-dom';
import { useState } from 'react';
import ContactPage from './pages/ContactPage/ContactPage';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import B2BPage from './pages/B2BPage/B2BPage';
import CatalogPage from './pages/CatalogPage/CatalogPage';
import NewsPage from './pages/NewsPage/NewsPage';
import CartPage from './pages/CartPage/CartPage';
import MainPageWinter from './pages/MainPageWinter/MainPageWinter';
import MainPageSummer from './pages/MainPageSummer/MainPageSummer';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import OneNewsPage from './pages/OneNewsPage/OneNewsPage';

function App() {
  const [isWinter, setIsWinter] = useState<boolean>(true);
  const toggleWinter = () => setIsWinter(!isWinter);

  return (
    <div className="app">
      <Header isWinter={isWinter} setIsWinter={toggleWinter} />
      <Routes>
        <Route path="/" element={isWinter ? <MainPageWinter /> : <MainPageSummer />} />
        <Route path="/b2b" element={<B2BPage />} />
        <Route path="/shop" element={<CatalogPage />} />
        <Route path="/news" element={<NewsPage />} />
        <Route path="/news/:id" element={<OneNewsPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer isWinter={isWinter} setIsWinter={toggleWinter} />
    </div>
  );
}

export default App;
