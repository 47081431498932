import { Link } from 'react-router-dom';
import styles from './MainScreenSection.module.scss';

function MainScreenSection() {
  return (
    <section className={styles.main}>
      <div className="wrap">
        <div className={styles.textWrap}>
          <h1 className={styles.title}>EverRest</h1>
          <h2 className={styles.subtitle}>магазин спортивных товаров в Ангарске</h2>
          <Link className={styles.button} to="/contact">Как нас найти</Link>
        </div>
      </div>
    </section>
  );
}

export default MainScreenSection;
