import { Link } from 'react-router-dom';
import styles from './NotFoundPage.module.scss';

function NotFoundPage() {
  return (
    <div className="wrap">
      <div className={styles.notFound}>
        <p className={styles.text}>Такой страницы не существует</p>
        <Link className={styles.button} to="/">Перейти на главную</Link>
      </div>
    </div>
  );
}

export default NotFoundPage;
