import styles from './Services.module.scss';

function Services() {
  return (
    <section className={styles.services}>
      <div className={styles.bgShadow} />
      <div className="wrap">
        <div className={styles.textWrap}>
          <h1 className={styles.title}>Сервис и ремонт</h1>
          <h2 className={styles.subtitle}>
            Сборка, настройка и подгонка под Вас.
            <br />
            Обслужим и отремонтируем в нашей мастерской.
          </h2>
        </div>
      </div>
    </section>
  );
}

export default Services;
