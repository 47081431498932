import MainScreenSection from './sections/MainScreenSection/MainScreenSection';
import BikeSection from './sections/BikeSection/BikeSection';
import Services from './sections/Services/Services';
import ScooterSection from './sections/Scooter/ScooterSection';
import Halva from './sections/Halva/Halva';
import Backpack from './sections/Backpack/Backpack';
import BrandsSummer from './sections/BrandsSummer/BrandsSummer';
import News from './sections/News/News';
import TentSection from './sections/TentSection/TentSection';
import SupCollection from './sections/SupCollection/SupCollection';

function MainPageSummer() {
  return (
    <>
      <MainScreenSection />
      <News />
      <SupCollection />
      <BikeSection />
      <Services />
      <TentSection />
      <Halva />
      <ScooterSection />
      <BrandsSummer />
      <Backpack />
    </>
  );
}

export default MainPageSummer;
