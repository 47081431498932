import './CartPage.module.scss';

function CartPage() {
  return (
    <div className="wrap">
      <p>Страница корзины магазина</p>
    </div>
  );
}

export default CartPage;
