import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import ScooterImg from '../../../../img/scooter.jpg';
import Item from '../../../../interfaces/itemInterface';

import styles from './ScooterSection.module.scss';

function ScooterSection() {
  const [scooters, setScooters] = useState<Array<Item>>([]);

  useEffect(() => {
    const apiUrl = 'db/scooter.json';
    axios.get(apiUrl).then((resp) => {
      const allScooter = resp.data;
      setScooters(allScooter);
    });
  }, []);

  return (
    <section className={styles.ski}>
      <div className="wrap">
        <h2 className={styles.title}>Самокаты</h2>
        <div className={styles.column}>
          <img className={styles.img} src={ScooterImg} alt="" />
          <div className={styles.wrapItems}>
            {scooters.map((scooter) => (
              <div className={styles.item} key={scooter.id}>
                <div className={styles.wrapImgItem}>
                  <img className={styles.imgItem} src={scooter.img} alt="bike" />
                  {/* <img className={styles.cartIcon} src={Cart} alt="" /> */}
                </div>
                <p className={styles.titleItem}>{scooter.name}</p>
                <p className={styles.priceItem}>
                  {new Intl.NumberFormat('ru').format(scooter.price)}
                  {' '}
                  руб.
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.wrapButton}>
          <Link className={styles.button} to="/contact">Посмотреть все самокаты в магазине</Link>
        </div>
      </div>
    </section>
  );
}

export default ScooterSection;
