import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import NewsItem from '../../interfaces/newsInterface';

import styles from './NewsPage.module.scss';

function NewsPage() {
  const [news, setNews] = useState<Array<NewsItem>>([]);

  useEffect(() => {
    const apiUrl = 'db/news.json';
    axios.get(apiUrl).then((resp) => {
      const allNews = resp.data;
      setNews(allNews);
    });
  }, []);

  return (
    <>
      <div className={styles.wrapNews} />
      <div className="wrap">
        <h2 className={styles.title}>Новости нашей компании</h2>
        <div className={styles.column}>
          {news.map((newsItem) => (
            <div className={styles.newsItem} key={newsItem.id}>
              <Link to={`/news/${newsItem.id}`}>
                <img className={styles.imgNews} src={newsItem.img} alt="" />
              </Link>
              <Link className={styles.titleNews} to={`/news/${newsItem.id}`}>
                {newsItem.title}
              </Link>
              <Link className={styles.linkNews} to={`/news/${newsItem.id}`}>Читать...</Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default NewsPage;
