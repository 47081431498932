import './CatalogPage.module.scss';

function CatalogPage() {
  return (
    <div className="wrap">
      <p>Страница интернет магазина</p>
    </div>
  );
}

export default CatalogPage;
