import { NavLink } from 'react-router-dom';
import styles from './NavMenu.module.scss';
import LogoBlack from '../../img/logo-black.svg';
import Switcher from '../Switcher/Switcher';

function NavMenu({ isWinter, setIsWinter } : { isWinter: boolean, setIsWinter: () => void }) {
  return (
    <nav className={styles.navMenu}>
      <ul className={styles.list}>
        <li><img src={LogoBlack} alt="логотип" /></li>
        <li><NavLink to="/">Главная</NavLink></li>
        {/* <li><NavLink to="/shop">Каталог</NavLink></li> */}
        <li><NavLink to="/b2b">Оптовые продажи</NavLink></li>
        <li><NavLink to="/news">Новости</NavLink></li>
        <li><NavLink to="/contact">Контакты</NavLink></li>
        {/* <li><NavLink to="/cart">Корзина</NavLink></li> */}
        <li><a href="tel:+79025674024">+7 (902) 567-40-24</a></li>
        <li><Switcher isWinter={isWinter} setIsWinter={setIsWinter} /></li>
      </ul>
    </nav>
  );
}

export default NavMenu;
