import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import BackpackImg from '../../../../img/backpack.jpg';
import styles from './Backpack.module.scss';
import Item from '../../../../interfaces/itemInterface';

function Backpack() {
  const [backpacks, setBackpacks] = useState<Array<Item>>([]);

  useEffect(() => {
    const apiUrl = 'db/backpack.json';
    axios.get(apiUrl).then((resp) => {
      const allBackpack = resp.data;
      setBackpacks(allBackpack);
    });
  }, []);

  return (
    <section className={styles.snowboard}>
      <div className="wrap">
        <h2 className={styles.title}>Рюкзаки</h2>
        <div className={styles.column}>
          <div className={styles.wrapItems}>
            {backpacks.map((backpack) => (
              <div className={styles.item} key={backpack.id}>
                <div className={styles.wrapImgItem}>
                  <img className={styles.imgItem} src={backpack.img} alt="bike" />
                  {/* <img className={styles.cartIcon} src={Cart} alt="" /> */}
                </div>
                <p className={styles.titleItem}>{backpack.name}</p>
                <p className={styles.priceItem}>
                  {new Intl.NumberFormat('ru').format(backpack.price)}
                  {' '}
                  руб.
                </p>
              </div>
            ))}
          </div>
          <img className={styles.img} src={BackpackImg} alt="" />
        </div>
        <div className={styles.wrapButton}>
          <Link className={styles.button} to="/contact">Посмотреть все рюкзаки в магазине</Link>
        </div>
      </div>
    </section>
  );
}

export default Backpack;
