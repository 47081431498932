import styles from './SupCollection.module.scss';

function SupCollection() {
  return (
    <section className={styles.newCollection}>
      <div className={styles.bgShadow} />
      <div className="wrap">
        <div className={styles.textWrap}>
          <h1 className={styles.title}>
            Новое поступление
            <br />
            SUP BOARD
          </h1>
          <h2 className={styles.subtitle}>Успевай! доски уже в магазине</h2>
        </div>
      </div>
    </section>
  );
}

export default SupCollection;
