import { NavLink } from 'react-router-dom';
import './NavMenuMobile.scss';
import { useState } from 'react';
import LogoBlack from '../../img/logo-black.svg';
import Switcher from '../Switcher/Switcher';

function NavMenuMobile({ isWinter, setIsWinter } : { isWinter: boolean, setIsWinter: () => void }) {
  const [isClosed, setIsClosed] = useState<boolean>(true);
  const setClosedMobileNavMenu = () => setIsClosed(true);

  return (
    <>
      <div className="mobileMenu">
        <div className="wrap">
          <ul className="list">
            <li>
              <NavLink onClick={setClosedMobileNavMenu} to="/">
                <img className="imgLogoMobile" src={LogoBlack} alt="логотип" />
              </NavLink>
            </li>
            <li><a className="linkTel" href="tel:+79025674024">+7 (902) 567-40-24</a></li>
            <li>
              <div
                id="hamburger"
                className={`hamburglar ${isClosed ? 'is-closed' : 'is-open'}`}
                onClick={() => setIsClosed(!isClosed)}
              >
                <div className="burger-icon">
                  <div className="burger-container">
                    <span className="burger-bun-top" />
                    <span className="burger-filling" />
                    <span className="burger-bun-bot" />
                  </div>
                </div>
                <div className="burger-ring">
                  <svg className="svg-ring">
                    <path
                      className="path"
                      fill="none"
                      stroke="#000"
                      strokeMiterlimit="10"
                      strokeWidth="4"
                      d="M 34 2 C 16.3 2 2 16.3 2 34 s 14.3 32 32 32 s
                      32 -14.3 32 -32 S 51.7 2 34 2"
                    />
                  </svg>
                </div>
                <svg width="0" height="0">
                  <mask id="mask">
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      stroke="#ff0000"
                      strokeMiterlimit="10"
                      strokeWidth="4"
                      d="M 34 2 c 11.6 0 21.8 6.2 27.4 15.5 c 2.9 4.8 5 16.5 -9.4 16.5 h -4"
                    />
                  </mask>
                </svg>
                <div className="path-burger">
                  <div className="animate-path">
                    <div className="path-rotation" />
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <nav className={`wrapShadowMenu ${isClosed ? '' : 'activeMenu'}`}>
        <ul className="shadowMenu">
          <li>
            <Switcher
              isWinter={isWinter}
              setIsWinter={setIsWinter}
              closeMobileMenu={setClosedMobileNavMenu}
            />
          </li>
          <li><NavLink onClick={setClosedMobileNavMenu} to="/">Главная</NavLink></li>
          {/* <li><NavLink to="/shop">Каталог</NavLink></li> */}
          <li><NavLink onClick={setClosedMobileNavMenu} to="/b2b">Оптовые продажи</NavLink></li>
          <li><NavLink onClick={setClosedMobileNavMenu} to="/news">Новости</NavLink></li>
          <li><NavLink onClick={setClosedMobileNavMenu} to="/contact">Контакты</NavLink></li>
          {/* <li><NavLink to="/cart">Корзина</NavLink></li> */}
          <li><a href="tel:+79025674024">+7 (902) 567-40-24</a></li>
          <li><a href="tel:+79526193314">+7 (952) 619-33-14</a></li>
        </ul>
      </nav>
    </>
  );
}

export default NavMenuMobile;
