import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import EquipmentImg from '../../../../img/equipment.jpg';
import Item from '../../../../interfaces/itemInterface';

import styles from './Equipment.module.scss';

function Equipment() {
  const [equips, setEquips] = useState<Array<Item>>([]);

  useEffect(() => {
    const apiUrl = 'db/equipment.json';
    axios.get(apiUrl).then((resp) => {
      const allEquips = resp.data;
      setEquips(allEquips);
    });
  }, []);

  return (
    <section className={styles.snowboard}>
      <div className="wrap">
        <h2 className={styles.title}>Экипировка</h2>
        <div className={styles.column}>
          <div className={styles.wrapItems}>
            {equips.map((equip) => (
              <div className={styles.item} key={equip.id}>
                <div className={styles.wrapImgItem}>
                  <img className={styles.imgItem} src={equip.img} alt="bike" />
                  {/* <img className={styles.cartIcon} src={Cart} alt="" /> */}
                </div>
                <p className={styles.titleItem}>{equip.name}</p>
                <p className={styles.priceItem}>
                  {new Intl.NumberFormat('ru').format(equip.price)}
                  {' '}
                  руб.
                </p>
              </div>
            ))}
          </div>
          <img className={styles.img} src={EquipmentImg} alt="" />
        </div>
        <div className={styles.wrapButton}>
          <Link className={styles.button} to="/contact">Посмотреть всю экипировку в магазине</Link>
        </div>
      </div>
    </section>
  );
}

export default Equipment;
