// eslint-disable-next-line import/no-extraneous-dependencies
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import styles from './ContactPage.module.scss';

function ContactPage() {
  const widthScreen = window.innerWidth;
  const defaultStateYMaps = widthScreen > 767
    ? {
      center: [52.507332, 103.847478],
      zoom: 17,
      controls: ['zoomControl', 'fullscreenControl'],
    }
    : {
      center: [52.508332, 103.846478],
      zoom: 17,
      controls: ['zoomControl', 'fullscreenControl'],
    };

  return (
    <>
      <div className={styles.everPhoto} />
      <div className={`wrap ${styles.wrapContact}`}>
        <h2 className={styles.subtitle}>Подробнее о всех товарах у нас в магазине:</h2>
        <div className={styles.contacts}>
          <h2>Магазин EVERREST</h2>
          <p>Адрес: г. Ангарск, 177 кв-л дом 22</p>
          <p>Телефоны: 8 (902) 567-40-24 </p>
          <p>
            &thinsp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;
            &ensp;&ensp;&ensp;8 (952) 619-33-14
          </p>
          <p>Режим работы: с 10.00 до 19.00</p>
          <p>ежедневно, без выходных</p>
        </div>
        <YMaps>
          <Map
            defaultState={defaultStateYMaps}
            modules={['control.ZoomControl', 'control.FullscreenControl']}
            instanceRef={(ref) => (ref && ref.behaviors.disable('scrollZoom'))}
            className={styles.mapYandex}
          >
            <Placemark
              modules={['geoObject.addon.balloon']}
              defaultGeometry={[52.508332, 103.846478]}
              properties={{
                balloonContentBody:
                    'EVERREST - магазин спортивных товаров',
              }}
            />
          </Map>
        </YMaps>
      </div>
    </>
  );
}

export default ContactPage;
