import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import BikeImg from '../../../../img/bike.jpg';
import Item from '../../../../interfaces/itemInterface';

import styles from './BikeSection.module.scss';

function BikeSection() {
  const [bikes, setBikes] = useState<Array<Item>>([]);

  useEffect(() => {
    const apiUrl = 'db/bike.json';
    axios.get(apiUrl).then((resp) => {
      const allBike = resp.data;
      setBikes(allBike);
    });
  }, []);

  return (
    <section className={styles.ski}>
      <div className="wrap">
        <h2 className={styles.title}>Велосипеды</h2>
        <div className={styles.column}>
          <img className={styles.img} src={BikeImg} alt="" />
          <div className={styles.wrapItems}>
            {bikes.map((bike) => (
              <div className={styles.item} key={bike.id}>
                <div className={styles.wrapImgItem}>
                  <img className={styles.imgItem} src={bike.img} alt="bike" />
                  {/* <img className={styles.cartIcon} src={Cart} alt="" /> */}
                </div>
                <p className={styles.titleItem}>{bike.name}</p>
                <p className={styles.priceItem}>
                  {new Intl.NumberFormat('ru').format(bike.price)}
                  {' '}
                  руб.
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.wrapButton}>
          <Link className={styles.button} to="/contact">Посмотреть все велосипеды в магазине</Link>
        </div>
      </div>
    </section>
  );
}

export default BikeSection;
