import styles from './B2BPage.module.scss';
import cardImg from '../../img/card.jpg';

function B2BPage() {
  return (
    <>
      <div className={styles.b2bPhoto} />
      <div className={`wrap ${styles.wrapB2B}`}>
        <div className={styles.discrB2B}>
          <h2>Оптовые продажи</h2>
          <p>Более 15 лет работаем в сфере оптовых продаж.</p>
          <p>Работаем с юридическими лицами, ИП и бюджетными организациями.</p>
          <p>Безналичная и наличная форма оплаты.</p>
          <p>Полный пакет документов.</p>
          <p>Оптовые цены устанавливаются индивидуально от объема.</p>
        </div>
        <div className={styles.card}>
          <p className={styles.cardTitle}>Скачать карточку клиента:</p>
          <a className={styles.linkImg} target="_blank" href="/img/card.pdf" rel="noreferrer">
            <img className={styles.cardImg} src={cardImg} alt="" />
          </a>
        </div>
      </div>
    </>
  );
}

export default B2BPage;
