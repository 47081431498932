import MainScreenSection from './sections/MainScreenSection/MainScreenSection';
import SkiSection from './sections/SkiSection/SkiSection';
import Services from './sections/Services/Services';
import NewCollection from './sections/NewCollection/NewCollection';
import SkatesSection from './sections/Skates/SkatesSection';
import Halva from './sections/Halva/Halva';
import Equipment from './sections/Equipment/Equipment';
import BrandsWinter from './sections/BrandsWinter/BrandsWinter';
import News from './sections/News/News';
import SnowBoardSection from './sections/SnowBoardSection/SnowBoardSection';

function MainPageWinter() {
  return (
    <>
      <MainScreenSection />
      <News />
      <NewCollection />
      <SkiSection />
      <Services />
      <SnowBoardSection />
      <Halva />
      <SkatesSection />
      <BrandsWinter />
      <Equipment />
    </>
  );
}

export default MainPageWinter;
